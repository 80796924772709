<template>
  <!--====================  service tab area ====================-->
  <div class="service-tab-area section-space--inner--60">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="section-title-area text-center">
            <h2 class="section-title section-space--bottom--50">Implementación</h2>
          </div>
        </div>
        <div class="col-lg-12">
          <!-- service tab wrapper -->
          <div class="service-tab-wrapper">
            <div class="row no-gutters">
              <div class="col-md-4">
                <div class="nav nav-tabs flex-column service-tab__link-wrapper">
                  <a
                    id="home"
                    class="nav-item nav-link"
                    @click.prevent="setActive('home')"
                    :class="{ active: isActive('home') }"
                    href="#home"
                  >
                    <span class="icon">
                      <img
                        v-if="activeItem == 'home'"
                        src="assets/img/icons/i1_over.png"
                        style="width: 40px; margin-right: 10px"
                        alt="User"
                      />
                      <img
                        v-else
                        src="assets/img/icons/i1.png"
                        style="width: 40px; margin-right: 10px"
                        alt="User"
                      />
                    </span>
                    <span class="text">Contrato</span>
                  </a>
                  <a
                    class="nav-item nav-link"
                    @click.prevent="setActive('profile')"
                    :class="{ active: isActive('profile') }"
                    href="#profile"
                  >
                    <span class="icon">
                      <img
                        v-if="activeItem == 'profile'"
                        src="assets/img/icons/i2_over.png"
                        style="width: 40px; margin-right: 10px"
                        alt="User"
                      />
                      <img
                        v-else
                        src="assets/img/icons/i2.png"
                        style="width: 40px; margin-right: 10px"
                        alt="User"
                      />
                    </span>
                    <span class="text">Apertura técnica de cuenta PayForU</span>
                  </a>
                  <a
                    class="nav-item nav-link"
                    @click.prevent="setActive('about')"
                    :class="{ active: isActive('about') }"
                    href="#about"
                  >
                    <span class="icon">
                      <img
                        v-if="activeItem == 'about'"
                        src="assets/img/icons/i3_over.png"
                        style="width: 40px; margin-right: 10px"
                        alt="User"
                      />
                      <img
                        v-else
                        src="assets/img/icons/i3.png"
                        style="width: 40px; margin-right: 10px"
                        alt="User"
                      />
                    </span>
                    <span class="text">Integración</span>
                  </a>
                  <a
                    class="nav-item nav-link"
                    @click.prevent="setActive('contact')"
                    :class="{ active: isActive('contact') }"
                    href="#contact"
                  >
                    <span class="icon">
                      <img
                        v-if="activeItem == 'contact'"
                        src="assets/img/icons/i4_over.png"
                        style="width: 40px; margin-right: 10px"
                        alt="User"
                      />
                      <img
                        v-else
                        src="assets/img/icons/i4.png"
                        style="width: 40px; margin-right: 10px"
                        alt="User"
                      />
                    </span>
                    <span class="text">Pruebas y puesta en marcha</span>
                  </a>
                </div>
              </div>
              <div class="col-md-8">
                <div class="tab-content service-tab__content-wrapper">
                  <div
                    class="tab-pane fade"
                    :class="{ 'active show': isActive('home') }"
                    id="home"
                  >
                    <div
                      class="service-tab__single-content-wrapper bg-img"
                      style="justify-content: center; align-items: center"
                      :style="{
                        'background-image':
                          'url(assets/img/backgrounds/banner1_Implementacionpayforu.jpg)',
                      }"
                    >
                      <div class="service-tab__single-content">
                        <h3 class="service-tab__title">Contrato</h3>
                        <p class="service-tab__text">
                          Consolidamos nuestros acuerdos y compromisos a través del
                          contrato de servicios.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    class="tab-pane fade"
                    :class="{ 'active show': isActive('profile') }"
                    id="profile"
                  >
                    <div
                      class="service-tab__single-content-wrapper bg-img"
                      style="justify-content: center; align-items: center"
                      :style="{
                        'background-image':
                          'url(assets/img/backgrounds/banner2_Implementacionpayforu.jpg)',
                      }"
                    >
                      <div class="service-tab__single-content">
                        <h3 class="service-tab__title">
                          Apertura técnica de cuenta PayForU
                        </h3>
                        <p class="service-tab__text">
                          Creamos tu propio panel de administración para el manejo
                          oportuno de las transacciones.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    class="tab-pane fade"
                    :class="{ 'active show': isActive('about') }"
                    id="about"
                  >
                    <div
                      class="service-tab__single-content-wrapper bg-img"
                      style="justify-content: center; align-items: center"
                      :style="{
                        'background-image':
                          'url(assets/img/backgrounds/banner3_Implementacionpayforu.jpg)',
                      }"
                    >
                      <div class="service-tab__single-content">
                        <h3 class="service-tab__title">Integración</h3>
                        <p class="service-tab__text">
                          Vinculamos tu perfil (app, web u otro) a las bondades y
                          beneficios de nuestro botón de pago.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    class="tab-pane fade"
                    :class="{ 'active show': isActive('contact') }"
                    id="contact"
                  >
                    <div
                      class="service-tab__single-content-wrapper bg-img"
                      style="justify-content: center; align-items: center"
                      :style="{
                        'background-image':
                          'url(assets/img/backgrounds/banner4_Implementacionpayforu.jpg)',
                      }"
                    >
                      <div class="service-tab__single-content">
                        <h3 class="service-tab__title">Pruebas y puesta en marcha</h3>
                        <p class="service-tab__text">
                          Validamos el paso a paso de la integración en ambientes de
                          prueba y ambientes reales para asegurar su correcto
                          funcionamiento.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--====================  End of service tab area  ====================-->
</template>

<script>
export default {
  data() {
    return {
      activeItem: "home",
    };
  },
  methods: {
    isActive(menuItem) {
      return this.activeItem === menuItem;
    },
    setActive(menuItem) {
      this.activeItem = menuItem;
    },
  },
};
</script>

<style lang="css" scoped>
.service-tab__text {
  text-align: justify;
}
</style>
