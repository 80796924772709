<template>
	<div class="main-container">
		<Header />

		<HeroOne :sliderData="data.heroOne" />

		<div class="row" style="margin: 20px 10px; text-align: center center !important">
			<div class="col-12" style="text-align: center">
				<router-link
					to="/pagar"
					style="text-transform: initial; background-color: #fd3000; padding: 20px"
					class="ht-btn ht-btn--round"
					><strong>Realiza tus pagos AQUÍ</strong>
				</router-link>
			</div>
			<div class="col-12" style="text-align: center">
				<a
					href="/#contact-form"
					style="text-transform: initial; font-size: 15px"
					class="ht-btn ht-btn--round"
					>Solicita el servicio para tu negocio</a
				>
			</div>
		</div>

		<Feature />

		<AboutSectionTwo />

		<LatestProject />

		<ServiceTabs />

		<div class="about-section section-space--inner--60">
			<div class="container">
				<div class="row row-25">
					<div class="col-12">
						<h2 class="subtitle text-center" style="font-weight: bold">
							Preguntas frecuentes
						</h2>
					</div>
					<div class="col-lg-6 col-12 mt--30" style="padding-top: 20px">
						<div v-for="item in payforu.FAQ" :key="item.id">
							<Accordion :question="item.question" :answer="item.answer" />
						</div>
					</div>
					<div class="col-lg-6 col-12 mb-30" id="contact-form">
						<ContactForm :platform="13" />
					</div>
				</div>
			</div>
		</div>

		<BrandCarousel />

		<Footer />

		<OffCanvasMobileMenu />

		<!-- WhatsApp Bottom -->
		<div id="whatsapp">
			<a
				href="https://api.whatsapp.com/send?phone=+584142698744&text=¡Hola!%20Quiero%20más%20información%20acerca%20de%20su%20servicio%20PayForU."
				title="Escríbenos por Whatsapp"
				data-tap="tooltip"
			>
				<i class="fa fa-whatsapp" style="color: #ffffff" aria-hidden="true"></i>
			</a>
		</div>

		<!-- back to top start -->
		<back-to-top class="scroll-top" bottom="100px" right="10px">
			<i class="ion-android-arrow-up"></i>
		</back-to-top>
		<!-- back to top end -->
	</div>
</template>

<script>
	import payforu from '../data/payforu.json'
	import Header from '@/components/Header'
	import HeroOne from '../components/sections/HeroOne'
	import Feature from '@/components/Feature'
	import AboutSectionTwo from '@/components/sections/AboutSectionTwo'
	import LatestProject from '../components/sections/LatestProject'
	import Accordion from '../components/Accordion'
	import ContactForm from '../components/ContactForm'
	import ServiceTabs from '@/components/sections/ServiceTabs'
	import BrandCarousel from '../components/BrandCarousel'
	import Footer from '../components/Footer'
	import OffCanvasMobileMenu from '@/components/OffCanvasMobileMenu'

	import data from '../data/slider.json'
	export default {
		data() {
			return {
				data,
				payforu,
			}
		},
		components: {
			Header,
			HeroOne,
			Feature,
			AboutSectionTwo,
			Accordion,
			ContactForm,
			LatestProject,
			ServiceTabs,
			BrandCarousel,
			Footer,
			OffCanvasMobileMenu,
		},
		metaInfo: {
			title: 'PayForU – Paga Fácil, Cómodo y Seguro',
			meta: [
				{ charset: 'utf-8' },
				{
					name: 'description',
					content: 'PayForU es una novedosa plataforma de pago multimoneda',
				},
				{
					name: 'keywords',
					content: 'servicio,comercio,finanzas,mall,pago,monedero',
				},
				{ name: 'author', content: 'LikeUGroup' },
				{ name: 'keywords', content: 'servicioslikeugroup@gmail.com' },
			],
			htmlAttrs: {
				lang: 'es',
				amp: true,
			},
		},
	}
</script>

<style lang="scss">
	#whatsapp {
		cursor: pointer;
		position: fixed;
		right: 10px;
		bottom: 20px;
		display: block;
		color: #ffffff;
		z-index: 1000;
		width: 60px;
		height: 60px;
		border-radius: 50%;
		text-align: center;
		font-size: 25px;
		line-height: 60px;
		background-color: #00e676;
	}
	@media only screen and (max-width: 479px) {
		.scroll-top {
			right: 10px !important;
			bottom: 80px !important;
		}
		#whatsapp {
			width: 50px;
			height: 50px;
			line-height: 50px;
			font-size: 20px;
		}
	}

	.ht-btn--round {
		padding: 20px;
		padding-top: 5px;
		padding-bottom: 5px;
		margin-top: 20px;
		font-size: 20px;
		background-color: #003d74;
	}
</style>
