<template>
	<div class="login-register_area" style="padding: 30px 0">
		<div class="container">
			<div class="row">
				<div class="col-12 center-block" style="margin: auto">
					<form action class="form" v-on:submit.prevent="submitForm">
						<div class="login-form">
							<h3 class="text-center" style="font-size: 20px">
								Ingresa tus datos para afiliar tu negocio al servicio de PayForU
							</h3>
							<hr />
							<h5 style="font-size: 16px">Datos del responsable</h5>
							<div class="row contact-form">
								<div class="col-md-6 col-12 mb--10">
									<label>Nombre *</label>
									<input
										v-model="form.name"
										id="name"
										required
										type="text"
										placeholder="Nombre"
									/>
								</div>
								<div class="col-md-6 col-12 mb--10">
									<label>Apellido *</label>
									<input
										v-model="form.lastname"
										id="lastname"
										required
										type="text"
										placeholder="Apellido"
									/>
								</div>
								<div class="col-md-6 col-12 mb--10">
									<label>Estado *</label>
									<input
										v-model="form.state"
										id="state"
										required
										type="text"
										placeholder="Estado"
									/>
								</div>
								<div class="col-md-6 col-12 mb--10">
									<label>Ciudad *</label>
									<input
										v-model="form.city"
										id="city"
										required
										type="text"
										placeholder="Ciudad"
									/>
								</div>
								<div class="col-12">
									<label>Email *</label>
									<input
										v-model="form.email"
										id="email"
										required
										type="email"
										placeholder="Ingresa tu correo electrónico"
									/>
								</div>
								<div class="col-12">
									<label>Teléfono *</label>
									<div class="row">
										<div
											class="col-md-7 col-sm-12"
											style="padding-right: 5px; margin-bottom: 15px"
										>
											<select
												v-model="form.phone_code"
												v-chosen
												required
												class="form-control"
												style="height: 30px; line-height: 1"
											>
												<option value="" disabled>Prefijo</option>
												<option value="58" selected>VE - Venezuela (+58)</option>
												<option value="376">AD - Andorra (+376)</option>
												<option value="971">AE - United Arab Emirates (+971)</option>
												<option value="93">AF - Afghanistan (+93)</option>
												<option value="1268">AG - Antigua And Barbuda (+1268)</option>
												<option value="1264">AI - Anguilla (+1264)</option>
												<option value="355">AL - Albania (+355)</option>
												<option value="374">AM - Armenia (+374)</option>
												<option value="599">AN - Netherlands Antilles (+599)</option>
												<option value="244">AO - Angola (+244)</option>
												<option value="672">AQ - Antarctica (+672)</option>
												<option value="54">AR - Argentina (+54)</option>
												<option value="1684">AS - American Samoa (+1684)</option>
												<option value="43">AT - Austria (+43)</option>
												<option value="61">AU - Australia (+61)</option>
												<option value="297">AW - Aruba (+297)</option>
												<option value="994">AZ - Azerbaijan (+994)</option>
												<option value="387">BA - Bosnia And Herzegovina (+387)</option>
												<option value="1246">BB - Barbados (+1246)</option>
												<option value="880">BD - Bangladesh (+880)</option>
												<option value="32">BE - Belgium (+32)</option>
												<option value="226">BF - Burkina Faso (+226)</option>
												<option value="359">BG - Bulgaria (+359)</option>
												<option value="973">BH - Bahrain (+973)</option>
												<option value="257">BI - Burundi (+257)</option>
												<option value="229">BJ - Benin (+229)</option>
												<option value="590">BL - Saint Barthelemy (+590)</option>
												<option value="1441">BM - Bermuda (+1441)</option>
												<option value="673">BN - Brunei Darussalam (+673)</option>
												<option value="591">BO - Bolivia (+591)</option>
												<option value="55">BR - Brazil (+55)</option>
												<option value="1242">BS - Bahamas (+1242)</option>
												<option value="975">BT - Bhutan (+975)</option>
												<option value="267">BW - Botswana (+267)</option>
												<option value="375">BY - Belarus (+375)</option>
												<option value="501">BZ - Belize (+501)</option>
												<option value="1">CA - Canada (+1)</option>
												<option value="61">CC - Cocos (keeling) Islands (+61)</option>
												<option value="243">
													CD - Congo, The Democratic Republic Of The (+243)
												</option>
												<option value="236">CF - Central African Republic (+236)</option>
												<option value="242">CG - Congo (+242)</option>
												<option value="41">CH - Switzerland (+41)</option>
												<option value="225">CI - Cote D Ivoire (+225)</option>
												<option value="682">CK - Cook Islands (+682)</option>
												<option value="56">CL - Chile (+56)</option>
												<option value="237">CM - Cameroon (+237)</option>
												<option value="86">CN - China (+86)</option>
												<option value="57">CO - Colombia (+57)</option>
												<option value="506">CR - Costa Rica (+506)</option>
												<option value="53">CU - Cuba (+53)</option>
												<option value="238">CV - Cape Verde (+238)</option>
												<option value="61">CX - Christmas Island (+61)</option>
												<option value="357">CY - Cyprus (+357)</option>
												<option value="420">CZ - Czech Republic (+420)</option>
												<option value="49">DE - Germany (+49)</option>
												<option value="253">DJ - Djibouti (+253)</option>
												<option value="45">DK - Denmark (+45)</option>
												<option value="1767">DM - Dominica (+1767)</option>
												<option value="1809">DO - Dominican Republic (+1809)</option>
												<option value="213">DZ - Algeria (+213)</option>
												<option value="593">EC - Ecuador (+593)</option>
												<option value="372">EE - Estonia (+372)</option>
												<option value="20">EG - Egypt (+20)</option>
												<option value="291">ER - Eritrea (+291)</option>
												<option value="34">ES - Spain (+34)</option>
												<option value="251">ET - Ethiopia (+251)</option>
												<option value="358">FI - Finland (+358)</option>
												<option value="679">FJ - Fiji (+679)</option>
												<option value="500">
													FK - Falkland Islands (malvinas) (+500)
												</option>
												<option value="691">
													FM - Micronesia, Federated States Of (+691)
												</option>
												<option value="298">FO - Faroe Islands (+298)</option>
												<option value="33">FR - France (+33)</option>
												<option value="241">GA - Gabon (+241)</option>
												<option value="44">GB - United Kingdom (+44)</option>
												<option value="1473">GD - Grenada (+1473)</option>
												<option value="995">GE - Georgia (+995)</option>
												<option value="233">GH - Ghana (+233)</option>
												<option value="350">GI - Gibraltar (+350)</option>
												<option value="299">GL - Greenland (+299)</option>
												<option value="220">GM - Gambia (+220)</option>
												<option value="224">GN - Guinea (+224)</option>
												<option value="240">GQ - Equatorial Guinea (+240)</option>
												<option value="30">GR - Greece (+30)</option>
												<option value="502">GT - Guatemala (+502)</option>
												<option value="1671">GU - Guam (+1671)</option>
												<option value="245">GW - Guinea-bissau (+245)</option>
												<option value="592">GY - Guyana (+592)</option>
												<option value="852">HK - Hong Kong (+852)</option>
												<option value="504">HN - Honduras (+504)</option>
												<option value="385">HR - Croatia (+385)</option>
												<option value="509">HT - Haiti (+509)</option>
												<option value="36">HU - Hungary (+36)</option>
												<option value="62">ID - Indonesia (+62)</option>
												<option value="353">IE - Ireland (+353)</option>
												<option value="972">IL - Israel (+972)</option>
												<option value="44">IM - Isle Of Man (+44)</option>
												<option value="91">IN - India (+91)</option>
												<option value="964">IQ - Iraq (+964)</option>
												<option value="98">IR - Iran, Islamic Republic Of (+98)</option>
												<option value="354">IS - Iceland (+354)</option>
												<option value="39">IT - Italy (+39)</option>
												<option value="1876">JM - Jamaica (+1876)</option>
												<option value="962">JO - Jordan (+962)</option>
												<option value="81">JP - Japan (+81)</option>
												<option value="254">KE - Kenya (+254)</option>
												<option value="996">KG - Kyrgyzstan (+996)</option>
												<option value="855">KH - Cambodia (+855)</option>
												<option value="686">KI - Kiribati (+686)</option>
												<option value="269">KM - Comoros (+269)</option>
												<option value="1869">KN - Saint Kitts And Nevis (+1869)</option>
												<option value="850">
													KP - Korea Democratic Peoples Republic Of (+850)
												</option>
												<option value="82">KR - Korea Republic Of (+82)</option>
												<option value="965">KW - Kuwait (+965)</option>
												<option value="1345">KY - Cayman Islands (+1345)</option>
												<option value="7">KZ - Kazakstan (+7)</option>
												<option value="856">
													LA - Lao Peoples Democratic Republic (+856)
												</option>
												<option value="961">LB - Lebanon (+961)</option>
												<option value="1758">LC - Saint Lucia (+1758)</option>
												<option value="423">LI - Liechtenstein (+423)</option>
												<option value="94">LK - Sri Lanka (+94)</option>
												<option value="231">LR - Liberia (+231)</option>
												<option value="266">LS - Lesotho (+266)</option>
												<option value="370">LT - Lithuania (+370)</option>
												<option value="352">LU - Luxembourg (+352)</option>
												<option value="371">LV - Latvia (+371)</option>
												<option value="218">LY - Libyan Arab Jamahiriya (+218)</option>
												<option value="212">MA - Morocco (+212)</option>
												<option value="377">MC - Monaco (+377)</option>
												<option value="373">MD - Moldova, Republic Of (+373)</option>
												<option value="382">ME - Montenegro (+382)</option>
												<option value="1599">MF - Saint Martin (+1599)</option>
												<option value="261">MG - Madagascar (+261)</option>
												<option value="692">MH - Marshall Islands (+692)</option>
												<option value="389">
													MK - Macedonia, The Former Yugoslav Republic Of (+389)
												</option>
												<option value="223">ML - Mali (+223)</option>
												<option value="95">MM - Myanmar (+95)</option>
												<option value="976">MN - Mongolia (+976)</option>
												<option value="853">MO - Macau (+853)</option>
												<option value="1670">
													MP - Northern Mariana Islands (+1670)
												</option>
												<option value="222">MR - Mauritania (+222)</option>
												<option value="1664">MS - Montserrat (+1664)</option>
												<option value="356">MT - Malta (+356)</option>
												<option value="230">MU - Mauritius (+230)</option>
												<option value="960">MV - Maldives (+960)</option>
												<option value="265">MW - Malawi (+265)</option>
												<option value="52">MX - Mexico (+52)</option>
												<option value="60">MY - Malaysia (+60)</option>
												<option value="258">MZ - Mozambique (+258)</option>
												<option value="264">NA - Namibia (+264)</option>
												<option value="687">NC - New Caledonia (+687)</option>
												<option value="227">NE - Niger (+227)</option>
												<option value="234">NG - Nigeria (+234)</option>
												<option value="505">NI - Nicaragua (+505)</option>
												<option value="31">NL - Netherlands (+31)</option>
												<option value="47">NO - Norway (+47)</option>
												<option value="977">NP - Nepal (+977)</option>
												<option value="674">NR - Nauru (+674)</option>
												<option value="683">NU - Niue (+683)</option>
												<option value="64">NZ - New Zealand (+64)</option>
												<option value="968">OM - Oman (+968)</option>
												<option value="507">PA - Panama (+507)</option>
												<option value="51">PE - Peru (+51)</option>
												<option value="689">PF - French Polynesia (+689)</option>
												<option value="675">PG - Papua New Guinea (+675)</option>
												<option value="63">PH - Philippines (+63)</option>
												<option value="92">PK - Pakistan (+92)</option>
												<option value="48">PL - Poland (+48)</option>
												<option value="508">PM - Saint Pierre And Miquelon (+508)</option>
												<option value="870">PN - Pitcairn (+870)</option>
												<option value="1">PR - Puerto Rico (+1)</option>
												<option value="351">PT - Portugal (+351)</option>
												<option value="680">PW - Palau (+680)</option>
												<option value="595">PY - Paraguay (+595)</option>
												<option value="974">QA - Qatar (+974)</option>
												<option value="40">RO - Romania (+40)</option>
												<option value="381">RS - Serbia (+381)</option>
												<option value="7">RU - Russian Federation (+7)</option>
												<option value="250">RW - Rwanda (+250)</option>
												<option value="966">SA - Saudi Arabia (+966)</option>
												<option value="677">SB - Solomon Islands (+677)</option>
												<option value="248">SC - Seychelles (+248)</option>
												<option value="249">SD - Sudan (+249)</option>
												<option value="46">SE - Sweden (+46)</option>
												<option value="65">SG - Singapore (+65)</option>
												<option value="290">SH - Saint Helena (+290)</option>
												<option value="386">SI - Slovenia (+386)</option>
												<option value="421">SK - Slovakia (+421)</option>
												<option value="232">SL - Sierra Leone (+232)</option>
												<option value="378">SM - San Marino (+378)</option>
												<option value="221">SN - Senegal (+221)</option>
												<option value="252">SO - Somalia (+252)</option>
												<option value="597">SR - Suriname (+597)</option>
												<option value="239">ST - Sao Tome And Principe (+239)</option>
												<option value="503">SV - El Salvador (+503)</option>
												<option value="963">SY - Syrian Arab Republic (+963)</option>
												<option value="268">SZ - Swaziland (+268)</option>
												<option value="1649">
													TC - Turks And Caicos Islands (+1649)
												</option>
												<option value="235">TD - Chad (+235)</option>
												<option value="228">TG - Togo (+228)</option>
												<option value="66">TH - Thailand (+66)</option>
												<option value="992">TJ - Tajikistan (+992)</option>
												<option value="690">TK - Tokelau (+690)</option>
												<option value="670">TL - Timor-leste (+670)</option>
												<option value="993">TM - Turkmenistan (+993)</option>
												<option value="216">TN - Tunisia (+216)</option>
												<option value="676">TO - Tonga (+676)</option>
												<option value="90">TR - Turkey (+90)</option>
												<option value="1868">TT - Trinidad And Tobago (+1868)</option>
												<option value="688">TV - Tuvalu (+688)</option>
												<option value="886">TW - Taiwan, Province Of China (+886)</option>
												<option value="255">
													TZ - Tanzania, United Republic Of (+255)
												</option>
												<option value="380">UA - Ukraine (+380)</option>
												<option value="256">UG - Uganda (+256)</option>
												<option value="1">US - United States (+1)</option>
												<option value="598">UY - Uruguay (+598)</option>
												<option value="998">UZ - Uzbekistan (+998)</option>
												<option value="39">
													VA - Holy See (vatican City State) (+39)
												</option>
												<option value="1784">
													VC - Saint Vincent And The Grenadines (+1784)
												</option>
												<option value="1284">VG - Virgin Islands, British (+1284)</option>
												<option value="1340">VI - Virgin Islands, U.s. (+1340)</option>
												<option value="84">VN - Viet Nam (+84)</option>
												<option value="678">VU - Vanuatu (+678)</option>
												<option value="681">WF - Wallis And Futuna (+681)</option>
												<option value="685">WS - Samoa (+685)</option>
												<option value="381">XK - Kosovo (+381)</option>
												<option value="967">YE - Yemen (+967)</option>
												<option value="262">YT - Mayotte (+262)</option>
												<option value="27">ZA - South Africa (+27)</option>
												<option value="260">ZM - Zambia (+260)</option>
												<option value="263">ZW - Zimbabwe (+263)</option>
											</select>
										</div>
										<div class="col-md-5 col-sm-12" style="padding-left: 15px">
											<input
												v-model="form.phone_number"
												id="phone"
												required
												type="number"
												placeholder="4240000000"
											/>
										</div>
									</div>
								</div>
								<hr />
							</div>
							<h5 style="font-size: 16px">Datos del negocio</h5>
							<div class="row contact-form">
								<div class="col-md-6">
									<div class="checkout-form-list">
										<label>
											Razón social
											<span class="required">*</span>
										</label>
										<input
											placeholder="Nombre de tu negocio"
											v-model="form.business_name"
											type="text"
										/>
									</div>
								</div>
								<div class="col-md-6">
									<div class="checkout-form-list">
										<label> RIF </label>
										<input placeholder="RIF" v-model="form.rif" type="text" />
									</div>
								</div>
								<div class="col-12 mb--10">
									<label>
										Actividad económica
										<span class="required">*</span>
									</label>
									<select
										v-model="form.business_type"
										v-chosen
										style="height: 30px; line-height: 1"
										required
										class="form-control chosen-select input"
									>
										<option value="" disabled>Seleccione</option>
										<option>Academias comerciales</option>
										<option>Accesorios para coser, hilos, telas, etc</option>
										<option>Accesorios y Artículos Femeninos</option>
										<option>Aeropuertos</option>
										<option>Agencias de Empleo</option>
										<option>Agencias de Festejos</option>
										<option>Agencias de Publicidad</option>
										<option>Agencias de viaje</option>
										<option>Agua</option>
										<option>Aire Acondicionado</option>
										<option>Alfombras, Tapicerias</option>
										<option>Alquiler de Botes y Lanchas</option>
										<option>Alquiler de equipos y Herramientas</option>
										<option>Alquiler de trajes</option>
										<option>Alquiler de Vehículos</option>
										<option>Alquiler de video</option>
										<option>Apuestas, Peñas</option>
										<option>Articulos de Cuero</option>
										<option>Artículos de Zapaterías</option>
										<option>Artículos para Artes Plásticas</option>
										<option>Asesorias Profesionales</option>
										<option>Asoc. cooperativa</option>
										<option>Asociación civil</option>
										<option>Atracciones turisticas</option>
										<option>Autolavado</option>
										<option>Boutique Caballeros</option>
										<option>Boutique Damas</option>
										<option>Campos de Golf</option>
										<option>Canchas de bowling (boliches)</option>
										<option>Carpinteria</option>
										<option>Casa de Empeño</option>
										<option>Casas de Cambio</option>
										<option>Celulares y Accesorios</option>
										<option>Centro de Fotocopiado</option>
										<option>Club</option>
										<option>Combustibles (lubricantes, madera, carbo</option>
										<option>Comida Rapida, Restaurant (sin Bar)</option>
										<option>Condominio Residenciales (Servicios Publicos)</option>
										<option>Construcción</option>
										<option>Contadores y Auditores</option>
										<option>Contratistas de Electricidad</option>
										<option>Corredor de Seguros</option>
										<option>Correo Aereo y Terrestre</option>
										<option>Cortinas, Tapicerias</option>
										<option>Detectives, proteccion, blindados</option>
										<option>Discotecas, Tascas, Bares y similares</option>
										<option>Discotiendas</option>
										<option>Distribuidores y kioskos de peridódicos</option>
										<option>Droguerias y Farmacias</option>
										<option>Dulcerias, Bombonerias y Heladerías</option>
										<option>Electrodomésticos</option>
										<option>Equip. ortopedicos y quirurg.(riñones)</option>
										<option>Equipos agrop. e ind.</option>
										<option>Equipos de Oficina</option>
										<option>Equipos de Salud</option>
										<option>Equipos Electricos</option>
										<option>Escuelas y Colegios</option>
										<option>Escuelas, Academias, Estudios de danza</option>
										<option>Estación de servicio isla</option>
										<option>Estación de servicio tienda</option>
										<option>Estacionamientos</option>
										<option>Estudio Fotográfico</option>
										<option>Ferreterías</option>
										<option>Filatelia y Numismatica</option>
										<option>Financiamiento de Primas de Seguros</option>
										<option>Floristeria</option>
										<option>Funerarias</option>
										<option>Galerias de Arte</option>
										<option>Gas</option>
										<option>Gimnasios</option>
										<option>Gráfica</option>
										<option>Hipertiendas</option>
										<option>Hospitales y Clínicas</option>
										<option>Hoteleria</option>
										<option>Ingenieros y Arquitectos</option>
										<option>Inst. financieras, vta servici</option>
										<option>Instituciones Financieras</option>
										<option>Institutos Tecnológicos</option>
										<option>Instrumentos musicales</option>
										<option>Internet</option>
										<option>Joyeria</option>
										<option>Laboratorios Médicos, Odontológicos</option>
										<option>Latonería y Pintura</option>
										<option>Lavanderia y Limpieza</option>
										<option>Librerías</option>
										<option>Licoreria</option>
										<option>Limpeza de Alfombras y Tapiceria</option>
										<option>Limpieza y Lavado de Ventanas</option>
										<option>Linea aerea internacionales</option>
										<option>Líneas Aereas</option>
										<option>Médicos</option>
										<option>Mudanzas</option>
										<option>Muebles y Accesorios del Hogar</option>
										<option>Muebles y Artículos del Hogar</option>
										<option>Óptcicas</option>
										<option>Optometristas, oftalmologos</option>
										<option>Organizaciones Religiosas</option>
										<option>Organizaciones sin fines de lucro, Beneficencias.</option>
										<option>Ostheopatic physicians</option>
										<option>Pago de Impuestos</option>
										<option>Panaderia, Pasteleria y Cafes</option>
										<option>Papelerias y Utiles Escolares</option>
										<option>Parques de diversiones</option>
										<option>Peajes y Puentes</option>
										<option>Perfumeria, Maquillaje y Cosmeticos</option>
										<option>Periódico</option>
										<option>Plomeria</option>
										<option>Postage stamps</option>
										<option>Productos Petroliferos y a fines</option>
										<option>Productos Quimicos y a fines</option>
										<option>Proveedores de cavas y estantes</option>
										<option>Quiropracticos</option>
										<option>Redes de computacion/serv.de informacion</option>
										<option>Reparacion de articulos Electronicos y similares</option>
										<option>Reparación de Equipos de Audio y Video</option>
										<option>Reproducciones y copias</option>
										<option>Resort</option>
										<option>Restaurant de Lujo (con Bar)</option>
										<option>Ropa de Bebes y Niños</option>
										<option>Ropa Masculina y Femenina</option>
										<option>Ropa para Toda la Familia</option>
										<option>Salas de Cines Teatros y Museo</option>
										<option>
											Salon de Belleza, Peluqueria, Manicuristas, Pedicuristas y similares
										</option>
										<option>Salones de billar y pool</option>
										<option>Salones de juego</option>
										<option>Salones de Masajes</option>
										<option>Sastres, Modistas, Arreglos y similares</option>
										<option>Serivicios Médicos en General</option>
										<option>Serv.de transport no clasificados</option>
										<option>Servicicos de Acompañantes</option>
										<option>Servicio de fumigacion</option>
										<option>Servicios de Ambulancias</option>
										<option>Servicios de campo (horticultura)</option>
										<option>Servicios de Computacion</option>
										<option>Servicios de cuidado infantil</option>
										<option>Servicios de Gruas</option>
										<option>Servicios de Piscinas</option>
										<option>
											Servicios de Telecomunicacion Nacional e Internacional
										</option>
										<option>Servicios domésticos</option>
										<option>Servicios gubernamentales no clasificado</option>
										<option>Servicios Legales, Abogados.</option>
										<option>Servicios Odontologicos</option>
										<option>Servicios Veterinarios</option>
										<option>Servicios y accesorios Marinos</option>
										<option>Talleres de aires acond. y eq. de refrig</option>
										<option>Talleres Mecánicos</option>
										<option>Taxis</option>
										<option>Teléfono</option>
										<option>Tienda de Articulos Religiosos</option>
										<option>Tienda de Ropa Deportiva</option>
										<option>Tiendas de Antiguedades</option>
										<option>Tiendas de Artículos deportívos y similares</option>
										<option>Tiendas de Computadoras Software</option>
										<option>Tiendas de delicateses</option>
										<option>Tiendas de Descuentos</option>
										<option>Tiendas de mascotas</option>
										<option>Tiendas de Pinturas, Barniz, Papel Tapiz y similares</option>
										<option>Tiendas de Productos lácteos</option>
										<option>Tiendas de radios, televisión, eq./sonid</option>
										<option>Tiendas de regalos, tarjeterias</option>
										<option>Tiendas de Variedades</option>
										<option>Tintorerias</option>
										<option>Toldos y persianas</option>
										<option>Transporte de pasajeros (Terrestre y Marítimo)</option>
										<option>Transporte, paradas de camiones</option>
										<option>TV por cable y similares</option>
										<option>U.k petrol stations, electronic hot file</option>
										<option>U.k. supermarkets, electronic hot file</option>
										<option>Uniformes</option>
										<option>Universidades</option>
										<option>Venta de bicicletas</option>
										<option>Ventas de lanchas</option>
										<option>Ventas de motos</option>
										<option>
											Ventas de respuestos para vehículos,camiones,motos y similares
										</option>
										<option>Ventas de vehículos y camiones</option>
										<option>Viveros, Equipos de jardineria y similares</option>
										<option>Zapaterías</option>
										<option>Zapateros</option>
									</select>
								</div>
								<div
									v-if="form.business_type == 'other'"
									style="padding-top: 20px"
									class="col-md-12 mb--10"
								>
									<label>Explique su actividad económica</label>
									<input
										placeholder="Explica brevemente la actividad económica de tu negocio"
										v-model="form.activityDetail"
										required
										type="text"
									/>
								</div>
							</div>

							<h5 style="font-size: 16px; padding-top: 20px">Mensaje</h5>
							<div class="row contact-form">
								<div class="col-12 mb--10">
									<textarea
										placeholder="Tu mensaje"
										style="height: 50px"
										v-model="form.message"
										required
									></textarea>
								</div>
								<div class="col-md-12">
									<div class="row">
										<button type="submit" style="margin: auto" class="btn ht-btn--round">
											Enviar
										</button>
									</div>
									<p v-if="error" style="padding-top: 10px" class="error">
										Ocurrió un error, intente de nuevo por favor.
									</p>
								</div>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import axios from 'axios'

	let selects = document.getElementsByClassName('chosen')

	selects.forEach((select) => {
		select.chosen()
	})

	export default {
		props: ['platform'],
		data() {
			return {
				config: {
					headers: {
						Authorization: `Bearer ` + sessionStorage.getItem('token'),
					},
				},
				form: {
					name: '',
					email: '',
					lastname: '',
					phone_number: '',
					rif: '',
					phone_code: '',
					business_type: '',
					message: '',
					business_name: '',
					state: '',
					city: '',
					activityDetail: '',
					plat_id: this.platform,
				},
				activity: '',
				economicActivity: '',
				phone: '',
				name: '',
				code: '',
				error: '',
				email: '',
				lastname: '',
				activityDetail: '',
				businessName: '',
				legalRepresentative: '',
				webPage: '',
				socialNetwork: '',
				socialNetworkUser: '',
				address: '',
				rif: '',
				showForm: false,
				taxData: '',
				currency: '',
				unitcost: '',
				subtotal: '',
				tax: process.env.VUE_APP_TAX,
				items: [],
				isVerified: false,
			}
		},

		methods: {
			submitForm() {
				if (this.form.business_type == 'other') {
					this.form.business_type = this.form.activityDetail
				}
				if (this.form.rif == '' || this.form.rif == null) {
					this.form.rif = 'N/A'
				}
				if (this.form.business_name == '' || this.form.business_name == null) {
					this.form.business_name = 'N/A'
				}
				axios
					.post(process.env.VUE_APP_AUTH_API + 'api/create-platform-request', {
						name: this.form.name,
						email: this.form.email,
						lastname: this.form.lastname,
						phone_number: this.form.phone_code + this.form.phone_number,
						rif: this.form.rif,
						business_type: this.form.business_type,
						message: this.form.message,
						business_name: this.form.business_name,
						city: this.form.city,
						state: this.form.state,
						plat_id: this.platform,
					})
					.then((res) => {
						console.log('success')
						this.$swal({
							title: `¡Hola!`,
							html: `Hemos recibido tu solicitud. <br>
                Nuestro equipo de Atención al Cliente te contactará pronto.<br>
                También puedes escribirnos a través de nuestro botón de WhatsApp.`,
							showCancelButton: false,
							icon: 'success',
							confirmButtonColor: '#e94e1b',
							showConfirmButton: true,
							confirmButtonText: 'Aceptar',
						}).then((result) => {
							if (result.isConfirmed) {
								location.reload()
							}
						})
					})
					.catch((error) => {
						console.log(error)
						this.$swal({
							title: 'Ocurrió un problema',
							text: 'Compruebe su conexion a internet e intente nuevamente',
							icon: 'info',
							showCancelButton: false,
							confirmButtonColor: '#e94e1b',
							showConfirmButton: true,
						}).then((result) => {
							if (result.isConfirmed) {
								location.reload()
							}
						})
						this.$swal.showValidationMessage(`Solicitud rechazada: ${error}`)
					})
					.finally(() => {
						//Perform action in always
					})
			},
		},
	}
</script>

<style lang="scss" scoped>
	@import '@/assets/scss/main.scss';

	.login-form .login-title {
		text-transform: initial;
	}

	.login-form input {
		padding: 0px 0px 0px 5px;
		height: auto;
	}

	.ht-btn--round {
		padding: 10px;
		padding-top: 5px;
		padding-bottom: 5px;
		margin-top: 20px;
		font-size: 17px;
		background-color: #003d74;
	}
	.chosen-container {
		position: relative;
		display: inline-block;
		vertical-align: middle;
		font-size: initial;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
	}
</style>
