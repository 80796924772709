<template>
  <div class="feature-icon-area section-space--inner--60">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="feature-icon-wrapper">
            <div class="row">
              <div class="col-lg-3 col-md-6" v-for="feature in data" :key="feature.id">
                <div class="single-feature-icon">
                  <div class="single-feature-icon__image" style="text-align:center;">
                    <img :src="feature.icon" class="img-fluid" alt="icon" />
                  </div>
                  <h3
                    class="single-feature-icon__title"
                    style="text-align:center;"
                  >{{ feature.title }}</h3>
                  <p
                    class="single-feature-icon__content"
                    style="text-align:justify;"
                  >{{ feature.desc }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import data from "../data/features.json";
export default {
  data() {
    return {
      data
    };
  }
};
</script>
